<template>
  <div>
    <div class="slideBg">
      <div class="container">
        <div class="mainSlide">
          <div class="slide" v-for="b in Banner" :key="b.BannerID">
            <a :href="b.TargetUrl ? b.TargetUrl : 'javascript:void(0)'"><img :src="b.ImageUrl | contentCDN" /></a>
          </div>
        </div>
        <div class="LoginBox">
          <div class="unLogin">
            <h2>登入</h2>
            <div class="formRow">
              <span>帳號</span>
              <input type="text" placeholder="請輸入您的帳號" required v-model="Login.UserName" />
              <div class="valid-feedback" v-if="false">Looks good!</div>
            </div>
            <div class="formRow">
              <span>密碼</span>
              <input type="password" placeholder="請輸入您的密碼" required v-model="Login.Password" />
              <div class="invalid-feedback" v-if="false">invalid</div>
            </div>
            <div class="submitBtn" @click="submit()">
              <a href="javascript:void(0);">登入</a>
            </div>
            <p class="otherLink">
              <a href="javascript:void(0);" @click="$router.push('/forget')">忘記密碼</a>
            <div class="joinMember colorx2">
              <a href="javascript:void(0);" class="" @click="showRegister"><img src="/img/join.png" alt=""></a>
              <router-link to="/registerm"><img src="/img/apply.png" alt=""></router-link>
            </div>
            </p>
          </div>
          <div class="hasLogin">
            <h2>WELCOME</h2>
            <template v-if="User">
              <div class="rowGroup name">
                <p>名稱</p>
                <h3>{{ User.Name }}</h3>
              </div>
              <div class="rowGroup level">
                <p>身分別</p>
                <h3>{{ User.UserType | userTypeFormat }}</h3>
              </div>
              <div class="rowGroup name">
                <p>剩餘點數</p>
                <h3>{{ User.Point | commaFormat }}</h3>
              </div>
            </template>
            <div class="rowGroup last">
              <a class="goMcenter" href="javascript:void(0)" @click="$router.push('/my')">我的帳號</a><a class="logOut"
                href="javascript:void(0)" @click="logout">登出</a>
            </div>
          </div>
        </div>
      </div>
      <div class="applyAreaBtn">
        <a target="_blank" href="http://www.journals.com.tw/tassm/contents/login.jsp">投稿專區</a>
      </div>
    </div>
    <div class="main">
      <div class="main_Left">
        <h2 class="t2">最新消息<span>News</span></h2>
        <div id="waterfallArea" v-waterfall after="imagesLoaded" :key="Page">
          <div class="content_box" v-for="d in Documents.Data" :key="d.NewsID">
            <a href="javascript:void(0);" @click="go(d)"><img :src="d.ImageUrl | contentCDN" />
              <h3>{{ d.Title }}</h3>
              <div class="date">{{ d.CreateTime | dateFormat }}</div>
            </a>
          </div>
        </div>
        <div class="pagination">
          <ul>
            <li v-for="p in Pages" :key="p">
              <a :class="{ act: p == Page }" href="javascript:void(0);" @click="goPage(p)">{{ p }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="main_Right">
        <h2>學會活動年度規劃</h2>
        <div class="home_events scrollContent">
          <div class="col_y" v-for="a in Activity" :key="a.Year">
            <h3>{{ a.Year }}</h3>
            <div class="event_item" v-for="d in a.Data" :key="d.ActivityID">
              <a :href="d.Url ? d.Url : 'javascript:void(0);'" target="_blank">
                <span>{{ d.Title }}</span>
                <p>{{ d.Body }}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="main_bottom">
        <h2 class="t2">相關連結<span>Links</span></h2>
        <div class="logos_slide" v-logoslick>
          <div class="ls_item">
            <a href="https://www.sa.gov.tw/" target="_blank"><img src="img/logos_01.png" /></a>
          </div>
          <div class="ls_item">
            <a href="http://www.journals.com.tw/tassm/contents/index.jsp" target="_blank"><img
                src="img/logos_02.png" /></a>
          </div>
          <div class="ls_item">
            <a href="https://iplay.sa.gov.tw/" target="_blank"><img src="img/logos_03.png" /></a>
          </div>
          <div class="ls_item">
            <a href="http://asiansportmanagement.com/" target="_blank"><img src="img/logos_04.png" /></a>
          </div>
          <div class="ls_item">
            <a href="https://tassm.org.tw世界華人體育管理協會gocasm/" target="_blank"><img src="img/logos_05.png" /></a>
          </div>
          <div class="ls_item">
            <a href="http://2012gsms.sport.au.edu.tw/wasmweb/page/default.htm" target="_blank"><img
                src="img/logos_06.png" /></a>
          </div>
          <div class="ls_item">
            <a href="https://watersafety.sa.gov.tw/" target="_blank"><img src="img/logos_07.png" /></a>
          </div>
        </div>
      </div>
    </div>
    <div id="registerDialog" class="popUp hide">
      <div class="alertWindow jcWindow">
        <div class="p_head">
          <p>申請帳號</p>
        </div>
        <div class="p_content">
          <p>您好，請選擇您欲申請的帳號之身份別：</p>
          <div class="joinChoose">
            <a href="javascript:void(0)" @click="$router.push('/register')"><img src="img/jc_01.jpg" />
              <h4>一般使用者</h4>
              <p>
                供一般民眾申請，通過申請即可於本網站觀看相關課程影片(部分影片須付費)
              </p>
            </a><a href="javascript:void(0)" @click="$router.push('/registerp')"><img src="img/jc_02.jpg" />
              <h4>專家委員</h4>
              <p>如：計劃委員、委員會召集人、委員會委員</p>
            </a><a href="javascript:void(0)" @click="$router.push('/registerl')"><img src="img/jc_03.jpg" />
              <h4>研習上課</h4>
              <p>如：證照Renew/...等</p>
            </a>
          </div>
        </div>
        <div class="p_foot">
          <a href="javascript:void(0)" @click="hideRegister">取消</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import user from "../plugins/user";

function initialSlide() { }

function initial() {
  $(function () {
    if ($(window).width() > 900) {
      //Trigger layout after each image loads and initialise Mansonry
      $("#waterfallArea").imagesLoaded(function () {
        debugger
      });

      //Fade Out the loading screen when all images loaded
      $("#waterfallArea")
        .imagesLoaded()
        .always(function (instance) {
          $(".loadingScreen").fadeOut();
        });
    }
  });

  (function ($) {
    $(window).on("load", function () {
      $(".scrollContent").mCustomScrollbar();
    });
  })(jQuery);

  //$(".logos_slide").slick();
}

const PageSize = 20;

$(".home .unLogin .submitBtn a").off("click");
export default {
  data() {
    return {
      Login: {
        UserName: "",
        Password: "",
      },
      User: null,
      Banner: [],
      Activity: [],
      Documents: {
        Data: [],
        Count: 0,
      },
      Page: 1,
      MaxPage: 0,
      Pages: [],
    };
  },
  mounted() {
    this.setBase("home");
    this.load();
  },
  methods: {
    async load() {
      var result = await this.$api.getHome();
      this.Banner = result.Banner;
      var activities = result.Activity.sort((a, b) => b.Year - a.Year);
      this.Activity = activities;
      setTimeout(() => {
        initialSlide();
      });

      if (user.user) {
        this.loadUser();
      }

      this.Page = parseInt(this.$route.query.p) || 1;
      this.loadNews();

      this.loadCustomJs();

      this.$nextTick(() => {
        $('.mainSlide').slick({
          arrows: true,
          autoplay: true,
          //- adaptiveHeight: true
        });
      });
    },
    async submit() {
      if (!this.validate(".unLogin")) {
        return;
      }
      try {
        var result = await this.$api.login(this.Login);
        user.setUser(result);
        this.$api.setToken(user.user.t);
        //this.loadUser();
        //this.$router.replace("/lessons");
        window.location.href = "/";
      } catch (e) {
        console.error(e);
        if (e.Status == 6) {
          alert('登入失敗，此帳號契約時間已過期');
        } else {
          alert("登入失敗，帳號或密碼錯誤");
        }
      }
    },
    async loadUser() {
      this.User = await this.$api.getMe();
      $(".unLogin").hide();
      $(".hasLogin").show();
    },
    async logout() {
      user.logout();
      $(".hasLogin").hide();
      $(".unLogin").show();
      this.User = null;
      this.$router.replace('/');
    },
    async loadNews() {
      var result = await this.$api.getNews(this.Page);
      this.Documents = result.Documents;
      this.Pages = [];
      this.MaxPage = Math.ceil(this.Documents.Count / PageSize);
      for (var p = -4; p < 4; p++) {
        var px = p + this.Page;
        if (px > 0 && px <= this.MaxPage) {
          this.Pages.push(px);
        }
      }

      setTimeout(() => {
        initial();
      });
    },
    go(doc) {
      this.$router.push("/news/" + doc.NewsID);
    },
    goPage(p) {
      if (this.Page != p) {
        this.$router.push("/?p=" + p);
      }
    },
    showRegister() {
      $("#registerDialog").removeClass("hide");
    },
    hideRegister() {
      $("#registerDialog").addClass("hide");
    },
  },
  watch: {
    $route(to, from) {
      this.Page = this.$route.query.p;
      this.load();
    },
  },
};
</script>
